'use client';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  okxWallet,
  trustWallet,
  safepalWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

import {
  type Storage,
  cookieStorage,
  createConfig,
  createStorage,
} from 'wagmi';
import { publicTransports, publicWagmiConfig } from './publicWagmiConfig';
import { arbitrum, base, bsc, mainnet } from 'viem/chains';
export const DEBOUNCE_DELAY = 300; // Adjust this value as needed (in milliseconds)
export const NATIVE_TOKEN = '0x0000000000000000000000000000000000000000';

export const NATIVE_TOKENS = {
  [mainnet.id]: {
    symbol: mainnet.nativeCurrency.symbol,
    decimals: mainnet.nativeCurrency.decimals,
    chainId: mainnet.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  [bsc.id]: {
    symbol: bsc.nativeCurrency.symbol,
    decimals: bsc.nativeCurrency.decimals,
    chainId: bsc.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png',
  },
  [base.id]: {
    symbol: base.nativeCurrency.symbol,
    decimals: base.nativeCurrency.decimals,
    chainId: base.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  [arbitrum.id]: {
    symbol: arbitrum.nativeCurrency.symbol,
    decimals: arbitrum.nativeCurrency.decimals,
    chainId: arbitrum.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
};
export const DEFAULT_POLLING_INTERVAL = 4_000;

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [metaMaskWallet, trustWallet, okxWallet,safepalWallet, walletConnectWallet],
    },
  ],
  {
    appName: 'SGS Trade',
    projectId: '85605e731b4cf3b5115ae56f6d2580ad',
  }
);

export const createProductionConfig = ({
  useCookies,
}: {
  useCookies: boolean;
}) => {
  let storage: Storage | undefined = undefined;
  if (useCookies) {
    storage = createStorage({
      storage: cookieStorage,
    });
  } else if (typeof window !== 'undefined') {
    storage = createStorage({ storage: window.localStorage });
  }

  return createConfig({
    ...publicWagmiConfig,
    transports: publicTransports,
    pollingInterval: DEFAULT_POLLING_INTERVAL,
    connectors,
    storage,
    ssr: true,
    
  });
};
export const defaultsTokens = {
  [mainnet.id]: {
    tokenIn: '0x0000000000000000000000000000000000000000',
    tokenOut: '0xb72E76cCf005313868DB7b48070901a44629da98',
  },
  [bsc.id]: {
    tokenIn: '0x0000000000000000000000000000000000000000',
    tokenOut: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  },
  [base.id]: {
    tokenIn: '0x0000000000000000000000000000000000000000',
    tokenOut: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  },
  [arbitrum.id]: {
    tokenIn: '0x0000000000000000000000000000000000000000',
    tokenOut: '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  },
};
