import { combineReducers } from 'redux';
import swapSlice from './slices/swapSlice';
import controllerSlice from './slices/controllerSlice'
import swapInputSlice from './slices/swapInputSlice'
import tokenManagerSlice from './slices/tokensManager'
export const rootReducer = combineReducers({
  swapSlice,
  controllerSlice,
  swapInputSlice,
  tokenManagerSlice
});
