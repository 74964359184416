'use client';

import { defaultsTokens } from '@/config/config';
import { useAppDispatch } from '@/hooks/useStore';
import { setClear } from '@/store/slices/swapInputSlice';
import { watchAccount } from '@wagmi/core';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { createContext, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { mainnet } from 'viem/chains';
import { useConfig } from 'wagmi';
import {client} from './query-client-provider'

interface SwapProviderProps {
  children: React.ReactNode;
}

interface State {
  mutate: {
    setSrcChain(chainId: number): void;
    setDestChain(chainId: number): void;
    setChain(chainId: number): void;
    setSrc(token: string, chainId: number): void;
    setDest(token: string, chainId: number): void;
    handleSwap(): void
  };
  state: {
    srcChain: number;
    destChain: number;
    srcToken: string;
    destToken: string;
  };
}

const SwapContext = createContext<State | null>(null);

const SwapProvider: FC<SwapProviderProps> = ({ children }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const config = useConfig();
  const dispatch = useAppDispatch()
  const defaultedParams = useMemo(() => {
    const params = new URLSearchParams(searchParams?.toString() || '');
    const defaultChainId = mainnet.id.toString();
    const defaultTokens = defaultsTokens[mainnet.id as keyof typeof defaultsTokens] || { tokenIn: '', tokenOut: '' };

    if (!params.has('srcChain')) params.set('srcChain', defaultChainId);
    if (!params.has('destChain')) params.set('destChain', defaultChainId);
    if (!params.has('srcToken')) params.set('srcToken', defaultTokens.tokenIn);
    if (!params.has('destToken')) params.set('destToken', defaultTokens.tokenOut);

    return params;
  }, [searchParams]);

  const updateParams = useCallback((updates: Record<string, string>) => {
    const newParams = new URLSearchParams(defaultedParams);
    Object.entries(updates).forEach(([key, value]) => newParams.set(key, value));
    router.push(`${pathname}?${newParams.toString()}`, { scroll: false });
  }, [defaultedParams, pathname, router]);
  const setSrcChain = useCallback((chainId: number) => {
    updateParams({ srcChain: chainId.toString() });
  }, [updateParams]);
  
  const setDestChain = useCallback((chainId: number) => {
    updateParams({ destChain: chainId.toString() });
  }, [updateParams]);

  const setChain = useCallback((chainId: number) => {
    updateParams({ srcChain: chainId.toString(), destChain: chainId.toString() })
  }
    
    , [updateParams]);
  
  const setSrc = useCallback((token: string, chainId: number) => updateParams({ srcToken: token , srcChain: chainId.toString()}), [updateParams]);
  const setDest = useCallback((token: string, chainId: number) => updateParams({ destToken: token , destChain: chainId.toString()}), [updateParams]);

  const state = useMemo(() => ({
    srcChain: Number(defaultedParams.get('srcChain')),
    destChain: Number(defaultedParams.get('destChain')),
    srcToken: defaultedParams.get('srcToken') || '',
    destToken: defaultedParams.get('destToken') || '',
  }), [defaultedParams]);

  useEffect(() => {
    return watchAccount(config, {
      onChange: ({ chain }) => {
        if (chain) updateParams({ srcChain: chain.id.toString() });
      },
    });
  }, [config, updateParams]);

  const handleSwap = useCallback(() => {
    const newSrcChain = state.destChain;
    const newDestChain = state.srcChain;
    const newSrcToken = state.destToken;
    const newDestToken = state.srcToken;
    dispatch(setClear())
    client.resetQueries({ queryKey: ['chainEstimation'] });
    updateParams({
      srcChain: newSrcChain.toString(),
      destChain: newDestChain.toString(),
      srcToken: newSrcToken,
      destToken: newDestToken,
    });
  }, [dispatch, state.destChain, state.destToken, state.srcChain, state.srcToken, updateParams])
  const contextValue = useMemo(() => ({
    mutate: { setSrcChain, setDestChain, setChain, setSrc, setDest, handleSwap },
    state,
  }), [setSrcChain, setDestChain, setChain, setSrc, setDest, handleSwap, state]);

  return <SwapContext.Provider value={contextValue}>{children}</SwapContext.Provider>;
};

const useSwapProvider = () => {
  const context = useContext(SwapContext);
  if (!context) {
    throw new Error('useSwapProvider can only be used inside SwapProvider');
  }
  return context;
};

export { SwapContext, SwapProvider, useSwapProvider };