import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useConnect } from '@/hooks/useConnect';
import { Button, ButtonProps } from '../ui/button';
import { FC, useCallback } from 'react';

export const ConnectButton: FC<ButtonProps> = ({  ...props }) => {
  const { pending,} = useConnect();
  const { openConnectModal } = useConnectModal();

  const onConnect = useCallback(() => {
    openConnectModal?.();
  }, [openConnectModal]);

  // Pending confirmation state
  // Awaiting wallet confirmation
  if (pending) {
    return (
      <Button loading {...props}>
        Authorize Wallet
      </Button>
    );
  }
  return (
    <Button
      {...props}
      onClick={onConnect}
      onKeyDown={onConnect}
     >
      <span className="hidden sm:block">Connect Wallet</span>
      <span className="block sm:hidden">Connect</span>
    </Button>
  );
};
