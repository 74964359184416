import { mainnet , bsc, arbitrum , base} from "viem/chains"
import { EthereumCircle } from "./EthereumCircle"
import { BinanceCircle } from "./BinanceCircle"
import { IconComponent } from "../type"
import { BaseCircle } from "./BaseCircle"
import { ArbitrumCircle } from "./ArbitrumCircle"

export const NETWORK_CIRCLE_ICON: Partial<Record<number, IconComponent>> = {
    [mainnet.id]: EthereumCircle,
    [bsc.id]: BinanceCircle,
    [base.id]: BaseCircle,
    [arbitrum.id]: ArbitrumCircle,
  }