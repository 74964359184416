import { defaultsTokens } from '@/config/config';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SwapSettingState {
  slippage: number; // Slippage tolerance percentage
  auto_slippage: boolean;
  DEFAULT_SLIPPAGE: number;
  srcToken: Token | null;
  destToken: Token | null;
}
interface Token {
  asset: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  chainId: number;
}
const defaultsToken = defaultsTokens['1'];
const initialState: SwapSettingState = {
  slippage: 0.5, // Default slippage tolerance
  auto_slippage: true,
  DEFAULT_SLIPPAGE: 0.5,
  srcToken: {
    asset: '',
    address: defaultsToken.tokenIn,
    name: 'Ethereum',
    symbol: 'ETH',
    decimals: 18,
    logoURI: '',
    chainId: 1,
  },
  destToken: {
    asset: '',
    address: defaultsToken.tokenOut,
    name: 'SquidGrow',
    symbol: 'SQGROW',
    decimals: 9,
    logoURI: '',
    chainId: 1,
  },
};

const swapSlice = createSlice({
  name: 'swapSettings',
  initialState,
  reducers: {
    // Update the slippage value
    setSlippage: (state, action: PayloadAction<number>) => {
      state.slippage = action.payload;
    },
    setAutoSlippage: (state, action: PayloadAction<boolean>) => {
      state.auto_slippage = action.payload;
    },
    // Set the source token
    setSourceToken: (state, action: PayloadAction<Token | null>) => {
      state.srcToken = action.payload;
    },
    // Set the destination token
    setDestinationToken: (state, action: PayloadAction<Token | null>) => {
      state.destToken = action.payload;
    },
    swapTokens: (state) => {
      
      const temp = state.srcToken;
      state.srcToken = state.destToken;
      state.destToken = temp;
    
    },
  },
});

export const {
  setSlippage,
  setAutoSlippage,
  setSourceToken,
  setDestinationToken,
  swapTokens
} = swapSlice.actions;

export default swapSlice.reducer;
