import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ControllerState {
  isSwapSettingOpen: boolean;
}

const initialState: ControllerState = {
  isSwapSettingOpen: false,
};

const controllerSlice = createSlice({
  name: 'controllerSettings',
  initialState,
  reducers: {
    setSwapSetting: (state, action: PayloadAction<boolean>) => {
      state.isSwapSettingOpen = action.payload;
    },
  },
});

export const { setSwapSetting } = controllerSlice.actions;

export default controllerSlice.reducer;
