import { http, createConfig, fallback } from '@wagmi/core';
import { mainnet, bsc, base, arbitrum } from 'wagmi/chains';

export const publicChains = [mainnet, bsc, base, arbitrum] as const;
export const Chain = publicChains.reduce((acc, chain) => {
  acc[chain.id] = chain.name;
  return acc;
}, {} as Record<number, string>);

// make use here powerfull rpc
export const publicTransports = {
  [mainnet.id]: fallback([
    http(
      'https://fabled-attentive-shard.quiknode.pro/519eb013e87067d296f5d6e4fd4eaab48feeec6d/'
    ),
    http('https://eth.llamarpc.com'),
    http('https://ethereum-rpc.publicnode.com'),
  ]),
  [arbitrum.id]: fallback([
    http(
      'https://convincing-dark-cloud.arbitrum-mainnet.quiknode.pro/e84b58f4fa4b4895282223f8170f0c7272737d26/'
    ),
    http('https://arbitrum.llamarpc.com'),
    http('https://arbitrum-one.publicnode.com'),
  ]),
  [bsc.id]: fallback([
    http(
      'https://muddy-late-dust.bsc.quiknode.pro/ca4bcef77f1a3c3d4f08b0524aa6a394ad1ebd46/'
    ),
    http('https://binance.llamarpc.com'),
    http('https://bsc-dataseed1.defibit.io'),
  ]),
  [base.id]: fallback([
    http(
      'https://polished-winter-layer.base-mainnet.quiknode.pro/33626d7c49fda94bad79a0598606b0e87f4f66ba/'
    ),
    http('https://base.llamarpc.com'),
    http('https://base-rpc.publicnode.com'),
  ]),
};
export const IMAGE_ASSET = {
  [mainnet.id]: 'ethereum',
  [arbitrum.id]: 'arbitrum',
  [base.id]: 'base',
  [bsc.id]: 'smartchain',
};

export const publicWagmiConfig = {
  chains: publicChains,
  transports: publicTransports,
  batch: {
    multicall: {
      wait: 64,
    },
  },
} as const satisfies Parameters<typeof createConfig>[0];

export type PublicWagmiConfig = ReturnType<
  typeof createConfig<
    (typeof publicWagmiConfig)['chains'],
    (typeof publicWagmiConfig)['transports']
  >
>;
