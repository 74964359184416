import { arbitrum, base, bsc, mainnet } from 'viem/chains';

export const TOKENS = [
  {
    address: '0x0000000000000000000000000000000000000000',
    asset: '0x0000000000000000000000000000000000000000',
    symbol: mainnet.nativeCurrency.symbol,
    name:  mainnet.nativeCurrency.symbol,
    decimals: mainnet.nativeCurrency.decimals,
    chainId: mainnet.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  {
    address: '0x0000000000000000000000000000000000000000',
    asset: '0x0000000000000000000000000000000000000000',
    symbol: bsc.nativeCurrency.symbol,
    name: bsc.nativeCurrency.symbol,
    decimals: bsc.nativeCurrency.decimals,
    chainId: bsc.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/logo.png',
  },
  {
    address: '0x0000000000000000000000000000000000000000',
    asset: '0x0000000000000000000000000000000000000000',
    symbol: base.nativeCurrency.symbol,
    name: base.nativeCurrency.symbol,
    decimals: base.nativeCurrency.decimals,
    chainId: base.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  {
    address: '0x0000000000000000000000000000000000000000',
    asset: '0x0000000000000000000000000000000000000000',
    symbol: arbitrum.nativeCurrency.symbol,
    name:  arbitrum.nativeCurrency.symbol,
    decimals: arbitrum.nativeCurrency.decimals,
    chainId: arbitrum.id,
    logo: 'https://assets-cdn.trustwallet.com/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  {
    asset: '0xb72E76cCf0015313868DB7b48070901a44629da98',
    address: '0xb72E76cCf005313868DB7b48070901a44629da98',
    name: 'SquidGrow',
    symbol: 'SQGROW',
    decimals: 9,
    logoURI: ``,
    chainId: 1,
  },
];
