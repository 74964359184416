import { TOKENS } from '@/config/tokens';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Token {
  asset: string;
  address: string;
  name: string;
  symbol: string;
  decimals: number;
  logoURI: string;
  chainId: number;
  logo?:string
}

interface TokenManagerState {
  tokenList: Token[];
}

const initialState: TokenManagerState = {
  tokenList: [...TOKENS as Token[]]
};

const tokenManagerSlice = createSlice({
  name: 'tokenManager',
  initialState,
  reducers: {
    addToken: (state, action: PayloadAction<Token>) => {
      state.tokenList.push(action.payload);
    },
    removeToken: (state, action: PayloadAction<{ address: string, chainId: number }>) => {
      state.tokenList = state.tokenList.filter(
        token => !(token.address === action.payload.address && token.chainId === action.payload.chainId)
      );
    },
    getDataBasedOnChainId: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        tokenList: state.tokenList.filter(token => token.chainId === action.payload)
      };
    }
  },
});

export const { addToken, removeToken, getDataBasedOnChainId } = tokenManagerSlice.actions;

export default tokenManagerSlice.reducer;