import { mainnet , bsc, base, arbitrum} from "viem/chains"
import { EthereumNaked } from "./EthereumNaked"
import { BinanceNaked } from "./BinanceNaked"
import { IconComponent } from "../type"
import { BaseNaked } from "./BaseNaked"
import { ArbitrumNaked } from "./ArbitrumNaked"

export const NETWORK_NAKED_ICON: Partial<Record<number, IconComponent>> = {
    [mainnet.id]: EthereumNaked,
    [bsc.id]: BinanceNaked,
    [base.id]: BaseNaked,
    [arbitrum.id]: ArbitrumNaked,
  }