import { useState } from 'react';
import {  useConnect as useWagmiConnect } from 'wagmi';

export const useConnect = (props?: Parameters<typeof useWagmiConnect>[0]) => {
  const [pending, setPending] = useState(false);

  const { connectAsync, ...rest } = useWagmiConnect(props);

  const _connectAsync = async (...args: Parameters<typeof connectAsync>) => {
    let result;

    try {
      setPending(true);
      result = await connectAsync(...args);

      return result;
    } catch (e) {
      console.error(e);
      throw e;
    } finally {
      setPending(false);
    }
  };

  return {
    ...rest,
    connect: connectAsync,
    connectAsync: _connectAsync,
    pending,
  };
};
